.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .spinner {
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-top-color: #007bff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  